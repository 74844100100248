import io, { Socket } from 'socket.io-client';

let socket: Socket;
let context: AudioContext | null = null;
let input: MediaStreamAudioSourceNode | null = null;
let globalStream: MediaStream | null = null;

const mediaConstraints: MediaStreamConstraints = {
  audio: true,
  video: false
};

interface TranscribeConfig {
  // Define your transcribeConfig properties here
}

interface SpeechData {
  data: string;
  isFinal: boolean;
}

interface AudioStreamerI {
  initRecording(
    transcribeConfig: TranscribeConfig,
    onData: (data: string, isFinal: boolean) => void,
    ws_url: string,
    onError: (error: string) => void
  ): Promise<void>;
  stopRecording(): void;
}

const AudioStreamer: AudioStreamerI = {
  async initRecording(
    transcribeConfig: TranscribeConfig,
    onData: (data: string, isFinal: boolean) => void,
    ws_url: string,
    onError: (error: string) => void
  ): Promise<void> {
    socket = io(ws_url, { transports: ['websocket'] });
    socket.emit('startGoogleCloudStream', { ...transcribeConfig });

    try {
      // Initialize AudioContext with a sample rate of 8000 Hz
      context = new AudioContext({ sampleRate: 8000 });

      // Load the AudioWorklet processor
      await context.audioWorklet.addModule('/processor.js'); // Ensure the file is in the public directory

      // Create the AudioWorkletNode
      const processor = new AudioWorkletNode(context, 'audio-processor');
      await context.resume(); // Resume the context if it was suspended

      const handleSuccess = (stream: MediaStream) => {
        globalStream = stream;

        // Create a MediaStreamSource from the microphone stream
        input = context!.createMediaStreamSource(stream);
        input.connect(processor);

        // Connect the processor to the destination (optional, for debugging)
        processor.connect(context!.destination);

        // Handle audio processing inside the worklet
        processor.port.onmessage = (event: MessageEvent) => {
          microphoneProcess(event.data);
        };
      };

      // Get user media (microphone input)
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      handleSuccess(stream);

      // Handle transcription data from WebSocket
      if (onData) {
        socket.on('speechData', (response: SpeechData) => {
          onData('\nAgent: ' + response.data, response.isFinal);
        });
      }

      // Handle errors
      socket.on('googleCloudStreamError', (error: string) => {
        if (onError) {
          onError(error);
        }
        closeAll();
      });

      // End Google Cloud stream
      socket.on('endGoogleCloudStream', () => {
        closeAll();
      });

    } catch (error) {
      console.error('Error initializing AudioWorklet:', error);
      if (onError) {
        onError('Error initializing audio processing');
      }
    }
  },

  stopRecording() {
    socket.emit('endGoogleCloudStream');
    closeAll();
  }
};

export default AudioStreamer;

// Helper functions
/**
 * Processes microphone data into a data stream
 *
 * @param {Float32Array} buffer Buffer from the microphone
 */
function microphoneProcess(buffer: Float32Array) {
  const left16: ArrayBuffer = convertFloat32ToInt16(buffer);
  socket.emit('binaryAudioData', left16);
}

/**
 * Converts a buffer from float32 to int16. Necessary for streaming.
 * sampleRateHertz of 8000.
 *
 * @param {Float32Array} buffer Buffer being converted
 */
function convertFloat32ToInt16(buffer: Float32Array): ArrayBuffer {
  const length = buffer.length;
  const int16Array = new Int16Array(length);

  for (let i = 0; i < length; i++) {
    int16Array[i] = Math.max(-1, Math.min(1, buffer[i])) * 32767;
  }

  return int16Array.buffer;
}

/**
 * Stops recording and closes everything down. Runs on error or on stop.
 */
function closeAll() {
  // Clear the listeners (prevents issue if opening and closing repeatedly)
  socket.off('speechData');
  socket.off('googleCloudStreamError');

  if (globalStream) {
    globalStream.getTracks().forEach((track) => track.stop());
  }

  if (input && context) {
    input.disconnect();
    input = null;
  }

  if (context) {
    context.close().then(() => {
      context = null;
    });
  }

  socket.disconnect();
}
